import styled from 'styled-components'
import { FONT_POPPINS } from '../../../../theme'

export const ProductsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  column-gap: 30px;
  padding: 0px 50px;
  margin-top: 50px;
  @media (max-width: 500px){
    margin-top: 30px;
    padding: 0px 10px;
    column-gap: 10px;
  }
`

export const Categories = styled.div`
  display: flex;
  overflow-x: auto;
  column-gap: 30px;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;
`

export const Category = styled.div`
  font-size: 20px;
  font-family: ${FONT_POPPINS};
  font-weight: 200;
  color : #000000;
`