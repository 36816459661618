import Category from "./Category"
import { fetchCategories } from "./Categories.action"
import { useContext, useEffect, useState } from "react"
import { CategoriesContainer, Title } from "./styles"
import { LinearProgress } from "@mui/material"
import { GlobalContext } from "../../App"

export const categoriesMap = {
  'electro': {
    'Heladeras': ["HELADERAS", 'heladeras cavas', 'heladera no frost', 'heladera cíclica', "heladeras", 'exhibidoras', 'heladeras-no-frost', 'heladeras-con-freezer', 'heladeras-sin-freezer'],
    "Freezers": ["freezers", "freezer"],
    "Televisores": ["televisores", "tv"],
    'Hornos': ["hornos", 'cocinas', 'cocina', 'anafes', 'electricos', 'hornitos-electricos', 'hornos-electricos', 'hornos-de-pan'],
    "Ventiladores": ["ventiladores", 'ventilación'],
    "Termotanques y Calefones": ["termotanques", "calefones", 'gas', 'termotanques eléctricos', 'termotanques a gas'],
    "Audio": ["sistema-de-audio", "audio"],
    "Aire Acondicionado": ["aire-acondicionado", "aires acondicionados", "aires-acondicionados", "aire acondicionado", 'split', 'ventana'],
    "Microondas": ["microondas", 'horno-microondas'],
    "Reproductores": ["reproductores-de-blu-ray-dvd-cd"],
    "Lavado": ["lavavajillas", "lavarropas", "lavado", 'lavasecarropas', 'planchas', 'secarropas', 'tablas-de-planchar', 'lavado?page=2'],
    "Aspiradoras": ["aspiradoras"],
    "Calefaccion": ["calefactores", 'calefacción'],
    "Campanas": ["campanas"],
    "Cavas": ["cavas-para-vinos", "cavas"],
    "Pequeños Electrodomesticos": ['peq. electrodomésticos', 'pequenos-electrodomesticos', 'balanzas', 'batidoras', 'cafeteras', 'exprimidores', 'fabrica-de-pastas', 'jugueras', 'procesadoras', 'm%c3%a1quinas-de-coser', 'licuadoras', 'pava-electrica', 'purificadores-de-agua', 'sandwicheras', 'tostadoras', 'vaporeras', 'freidoras', 'cafeteras y molinillos', 'cuidado personal', 'salud', 'ayudantes de cocina', 'desayuno', 'hogar']
  },
  'gaming': {
    'Microprocesadores': ['procesadores intel', 'procesadores amd', 'microprocesadores', 'procesadores', 'microprocesador'],
    'Almacenamiento': ['discos sólidos ssd', 'discos rígidos externos', 'discos rígidos internos', 'almacenamiento', 'ssd', 'discos sata', 'componentes pc discos accesorios', 'discos'],
    'Placas de Video': ['placas de video geforce', 'componentes pc placas video', 'placa de video', 'placas de video radeon amd', 'placas de video geforce', 'placas de video'],
    'Fuentes de Alimentación': ['fuentes de alimentación', 'fuentes', 'fuentes certificadas', 'fuentes alimentacion', 'fuentes de poder'],
    'Motherboards': ['mothers amd', 'mothers intel', 'motherboard', 'motherboards', 'componentes pc placas motherboards'],
    'Coolers': ['coolers cpu', 'coolers ventiladores', 'coolers fan', 'coolers'],
    'Refrigeracion': ['refrigeracion'],
    'Monitores': ['monitores y proyectores', 'monitores'],
    'Memorias Ram': ['memoria ram', 'memorias-ram', 'memorias ram', 'memorias'],
    'Gabinetes': ['gabinetes', 'gabinetes y fuentes']
  }
}

const Categories = () => {
  const [loading, setLoading] = useState(false)
  const globalContext = useContext(GlobalContext)

  const mapCategory = (category) => {
    for (const categoryTitle in categoriesMap[globalContext.classCategory]) {
      if (categoriesMap[globalContext.classCategory][categoryTitle].includes(category.toLowerCase())) {
        return categoryTitle
      }
    }
    return category
  }

  const handleCategories = async () => {
    setLoading(true)
    const response = await fetchCategories(globalContext.classCategory.toUpperCase())
    if (response.data) {
      globalContext.setCategories({ ...globalContext.categories, [globalContext.classCategory]: response.data.map((category) => category.trim().toLowerCase()) })
    }
    setLoading(false)
  }

  useEffect(() => {
    handleCategories()
  }, [])

  const handleOnClick = (category) => {
    globalContext.setSelectedCategory(category)
    let filteredProductsByCategory = globalContext.products[globalContext.classCategory].filter((product) => categoriesMap[globalContext.classCategory][category].includes(product.category.toLowerCase().trim()))
    globalContext.setFilteredProducts({ ...globalContext.filteredProducts, [globalContext.classCategory]: filteredProductsByCategory })
    globalContext.setCategorizedProducts({ ...globalContext.categorizedProducts, [globalContext.classCategory]: filteredProductsByCategory })
  }

  const generateCategoriesCard = () => {
    return (
      [...new Set(globalContext.categories[globalContext.classCategory].map((category) => { return mapCategory(category) }))].map((category, index) => {
        return (
          <Category
            isSelectedCategory={globalContext.selectedCategory === category}
            category={category}
            index={index}
            handleOnClick={(category) => handleOnClick(category)}
            key={index} />
        )
      })
    )
  }


  return (
    <div>
      <Title classCategory={globalContext.classCategory}>Categorias</Title>
      <CategoriesContainer>
        {globalContext.categories[globalContext.classCategory] && generateCategoriesCard()}
      </CategoriesContainer>
      {loading &&
        <LinearProgress />
      }
    </div>
  )
}

export default Categories