import styled from 'styled-components'

const handleStyleByClassCategory = classCategory => {
  switch (classCategory) {
    case "gaming":
      return {
        background: "#ffffff"
      };
    case "electro":
      return {
        background: "#ffffff"
      };
    case "alimentos":
      return {
        background: "#ffffff"
      };
    default:
      return "#fff";
  }
};

export const Container = styled.div`
  z-index: 10;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  ${({ classCategory }) => handleStyleByClassCategory(classCategory)};
`