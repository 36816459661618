import axios from "axios";
import lzstring from 'lz-string'
import axiosRetry from 'axios-retry';

const mockedProds = [
  {
    store: 'pc world',
    title: 'procesador amd',
    price: "9999",
    image_url: "url",
    historial: [
      {
        date: "12/12/22",
        price: "8000"
      },
      {
        date: "15/12/22",
        price: "9999"
      }
    ],
    category: 'procesador',
    url: "urlk",
    class: 'GAMING',
    last_update: "15/12/22"
  },
  {
    store: 'pc world',
    title: 'procesador amd',
    price: "9999",
    image_url: "url",
    historial: [
      {
        date: "12/12/22",
        price: "8000"
      },
      {
        date: "15/12/22",
        price: "9999"
      }
    ],
    category: 'procesador',
    url: "urlk",
    class: 'GAMING',
    last_update: "15/12/22"
  },
  {
    store: 'pc world',
    title: 'procesador amd',
    price: "9999",
    image_url: "url",
    historial: [
      {
        date: "12/12/22",
        price: "8000"
      },
      {
        date: "15/12/22",
        price: "9999"
      }
    ],
    category: 'procesador',
    url: "urlk",
    class: 'GAMING',
    last_update: "15/12/22"
  }
]
axiosRetry(axios, { retries: 3 });

export const fetchHomeProducts = async () => {
  let data;
  try {
    data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/home/recomendations`,
      {
        headers: {
          'x-api-key': "apikey"
        }
      });
    data.data = JSON.parse(lzstring.decompress(data.data))
  } catch (error) {
    console.log(error.message);
  }
  return data
}


export const fetchProducts = async (classCategory) => {
  let data;
  try {
    data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/all/${classCategory}`,
      {
        headers: {
          'x-api-key': "apikey"
        }
      });
    data.data = JSON.parse(lzstring.decompress(data.data))
  } catch (error) {
    console.log(error.message);
  }
  return data
}

export const fetchFirstLoadProducts = async () => {
  let data;
  try {
    data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/firstLoadProducts`,
      {
        headers: {
          'x-api-key': "apikey"
        }
      });

  } catch (error) {
    console.log(error.message);
  }
  return data;
}

export const postFavorite = async (productId) => {
  let data = null;
  try {
    data = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/products/favorite`,
      {
        productId: productId,
        userId: localStorage.getItem('username')
      }
    );
  } catch (error) {
    console.log(error.message);
  }
  return data;
}

export const deleteFavorite = async (productId) => {
  let data = null;
  try {
    data = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/products/favorite/delete`,
      {
        productId: productId,
        userId: localStorage.getItem('googleId')
      }
    );
  } catch (error) {
    console.log(error.message);
  }
  return data;
}

export const fetchFavorites = async () => {
  let data = null;
  try {
    data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/favorites/all`);
  } catch (error) {
    console.log(error.message);
  }
  return data;
}