import { useContext, useEffect, useState } from 'react'
import Joyride, { CallBackProps, ACTIONS, STATUS, EVENTS, Step } from 'react-joyride'
import theme from '../../theme'
import Cookies from 'js-cookie'
import { GlobalContext } from '../../App'


const TOUR_STEPS = [
  {
    target: '[data-tour="search-input"]',
    title: '¡Bienvenido a COMPAR-E!',
    content:
      'Nos encantaría mostrarte como funciona. ¿Empezamos?',
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    target: '[data-tour="fav-icon"]',
    title: 'Favoritos',
    content:
      'Al agregar un producto a favoritos podrás enterarte cuando su precio baje',
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    target: '[data-tour="graph-icon"]',
    title: 'Historial de precios',
    content:
      'Clickeando sobre el icono podrás visualizar la variacion del precio en el tiempo del producto.',
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    target: '[data-tour="store"]',
    title: 'Tienda',
    content:
      'De esta manera se indica cual es la tienda que vende el producto.',
    disableBeacon: true,
    placement: 'bottom',
  }
]

const GuidedTour = ({ ready = false }) => {
  const [status, setStatus] = useState(false)
  const [index, setIndex] = useState(0)
  const globalContext = useContext(GlobalContext)


  const locale = {
    back: 'Atrás',
    close: 'Cerrar',
    last: 'Cerrar',
    next: 'Siguiente',
    skip: 'Cerrar',
  }

  useEffect(() => {
    if (ready && Cookies.get('guided-tour') !== 'done') {
      setStatus(true)
    }
  }, [ready])

  const handleTour = async (data) => {
    const finished = STATUS.FINISHED === data.status
    const skipped = ACTIONS.CLOSE === data.action

    if (finished || skipped) {
      Cookies.set('guided-tour', 'done')
      if (finished) {
        globalContext.eventTrackGA('guided-tour', `Finished`, 'guided-tour')
      }
      if (skipped) {
        globalContext.eventTrackGA('guided-tour', `Skipped:${data.index}`, 'guided-tour')
      }
      setStatus(false)
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(data.type)) {
      setIndex(data.index + (data.action === ACTIONS.PREV ? -1 : 1))
    }
  }

  return (
    <Joyride
      steps={TOUR_STEPS}
      scrollOffset={200}
      locale={locale}
      run={status}
      continuous
      stepIndex={index}
      callback={handleTour}
      floaterProps={{
        disableAnimation: true,
        disableFlip: true,
      }}
      styles={{
        options: {
          primaryColor: theme.palette.primary.light,
        },
      }}
    />
  )
}

export default GuidedTour
