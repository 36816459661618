import axios from "axios";

export const postUser = async (userId, userEmail) => {
  let data = null;
  try {
    data = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user`,
      {
        userId: userId,
        userEmail: userEmail
      }
    );
  } catch (error) {
    console.log(error.message);
  }
  return data;
}
