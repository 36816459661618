import styled from "styled-components";
import { FONT_LATO } from "../../theme";

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  font-family: ${FONT_LATO};
  text-transform: capitalize;
  color: #333333;
  margin:1px;
  text-align: center;
  text-decoration: underline;
`

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const ProductContainer = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`

export const Container = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  @media (min-width: 800px){
    margin-top: 10px;
  }
`