import { useContext } from "react"
import { GlobalContext } from "../../App"
import BrandCarousel from "../BrandCarousel"
import HomeCategories from "../Category/HomeCategories"
import FeaturedProducts from "./HighlightedProducts/FeaturedProducts"
import PopularProducts from "./HighlightedProducts/PopularProducts"
import WeekendDeals from "./HighlightedProducts/WeekendDeals"
import HowItWorks from "./HowItWorks"
import { HomeContainer } from "./styles"

const Landing = () => {
  const globalContext = useContext(GlobalContext)

  return (
    <HomeContainer>
      <BrandCarousel />
      <HomeCategories />
      <FeaturedProducts />
      <WeekendDeals />
      <PopularProducts />
      <HowItWorks />
    </HomeContainer >
  )
}

export default Landing