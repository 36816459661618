import styled from 'styled-components'

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
  justify-content: center;
  padding: 0px 50px;
  margin-top: 40px;
  @media (max-width: 500px){
    margin-top: 30px;
  }
`

export const ProductWrapper = styled.div`
  width: fit-content;
  @media (max-width: 500px){
    
  }
`