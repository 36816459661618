import { CardContainer, Image, Title } from "./styles"

const Card = ({ title, imageSrc }) => {
  return (
    <CardContainer>
      <Image src={imageSrc} />
      <Title>{title}</Title>
    </CardContainer>
  )
}

export default Card