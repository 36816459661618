import { Box, Button, Card, CardContent, FormControl, InputLabel, LinearProgress, OutlinedInput, TextField, Typography } from "@mui/material"
import React from "react";
import { useState } from "react";


const RegisterForm = ({ onSubmitRegister, showVerificationCode, onSubmitVerificationCode }) => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    if (showVerificationCode) {
      onSubmitVerificationCode(e.target["verificationCode"].value)
    } else {
      onSubmitRegister(e.target["usernameRegister"].value, e.target["passwordRegister"].value, e.target["confirmationPassword"].value)
    }
    setLoading(false)
  }

  return (
    <Box className='content-center' style={{ marginTop: 50, padding: 10, maxWidth: 500 }}>
      <Card sx={{ zIndex: 1 }}>
        <CardContent sx={{ padding: "12px 9px 7px !important" }}>
          <Box sx={{ mb: 6 }}>
            <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
              Registro
            </Typography>
            <Typography variant='body2'>
              {
                showVerificationCode ?
                  'El codigo de verificación fue enviado a su correo'
                  :
                  'Complete todos los campos'
              }
            </Typography>
          </Box>
          <form noValidate autoComplete='off' onSubmit={handleSubmit}>
            {
              showVerificationCode ?
                <>
                  <TextField fullWidth id='verificationCode' label='Codigo de Verificacion' sx={{ marginBottom: 8 }} />
                  {loading ?
                    <LinearProgress />
                    :
                    <Button
                      fullWidth
                      size='large'
                      variant='contained'
                      sx={{ marginBottom: 7, marginTop: 7 }}
                      type="submit"
                    >
                      Verificar
                    </Button>}
                </>
                :
                <>
                  <TextField fullWidth id='usernameRegister' label='Email' sx={{ marginBottom: 8 }} />
                  <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel htmlFor='auth-login-password'>Contraseña</InputLabel>
                    <OutlinedInput
                      label='Password'
                      id='passwordRegister'
                      type={'password'}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='auth-login-password'>Confirmar Contraseña</InputLabel>
                    <OutlinedInput
                      label='Confirmation-Password'
                      id='confirmationPassword'
                      type={'password'}
                    />
                  </FormControl>
                  {loading ?
                    <LinearProgress />
                    :
                    <Button
                      fullWidth
                      size='large'
                      variant='contained'
                      sx={{ marginBottom: 7, marginTop: 7 }}
                      type="submit"
                    >
                      Registrarse
                    </Button>}
                </>
            }
          </form>
        </CardContent>
      </Card>
    </Box>
  )
}


export default React.memo(RegisterForm)