import { useContext } from "react"
import { GlobalContext } from "../../../../App"
import Product from "../../../Product/Product"
import { Title } from "../../styles"
import { Container } from "../styles"
import { Categories, Category, ProductsContainer } from "./styles"


const FeaturedProducts = () => {
  const globalContext = useContext(GlobalContext)

  const mapProducts = () => {
    return (
      globalContext.homeProducts['hot_deals'] && globalContext.homeProducts['hot_deals'].map((product, index) => (
        <Product singleModeView={false} product={product} isFavorite={false} highlight={true} />
      ))
    )
  }
  return (
    <Container>
      <Title>Productos Destacados</Title>
      {/*<Categories>
        <Category>Electrodomesticos</Category>
        <Category>Gaming</Category>
  </Categories>*/}
      <ProductsContainer>
        {mapProducts()}
      </ProductsContainer>
    </Container>
  )
}

export default FeaturedProducts