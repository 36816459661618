import styled, { css, keyframes } from 'styled-components'
import theme, { FONT_LATO } from '../../theme'


const gamingBgAnimation = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

const handleStyleByClassCategory = classCategory => {
  switch (classCategory) {
    case "gaming":
      return {
        background: "linear-gradient(320deg, #00249e 10%,#ff2020 85%,#04005e 20%)",
        backgroundSize: "200% 100%",
      };
    case "electro":
      return {
        background: "linear-gradient(295deg, rgba(235, 236, 240, 1) 0%, rgba(190, 190, 190, 1) 35%, rgba(242, 242, 242, 1) 100%)",
      };
    case "alimentos":
      return {
        background: "linear-gradient(115deg, rgba(142, 229, 63, 1) 0%, rgba(241, 241, 118, 1) 35%, rgba(255, 147, 0, 1) 100%)",
      };
    default:
      return "#fff";
  }
};

const handleColorType = classCategory => {
  switch (classCategory) {
    case "electro":
      return "#3f3f3f";
    case "alimentos":
      return "#3f3f3f";
    default:
      return "#fff";
  }
};

export const Container = styled.div`
  ${({ classCategory }) => handleStyleByClassCategory(classCategory)};
  min-height: 100vh;
  ${props => props.classCategory === 'gaming' && css`
    animation: ${gamingBgAnimation} 8s ease infinite;
  `}
  
`

export const Title = styled.div`
  font-family: ${FONT_LATO};
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  color: ${({ classCategory }) => handleColorType(classCategory)};
  text-transform: capitalize;
  padding: 30px 0px 3px 15px;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`