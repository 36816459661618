import Search from '../Search/Search'
import Products, { formatPrice } from "../Product/Products"
import { useContext } from 'react'
import { categories, GlobalContext } from '../../App'
import Header from '../Header/Header'
import { useEffect } from 'react'
import { useState } from 'react'
import { Container, LoadingContainer, Title } from './styles'
import { fetchFavorites, fetchProducts } from '../Product/Product.actions'
import Categories from '../Category/Categories'
import { CircularProgress } from '@mui/material'
import GuidedTour from '../GuidedTour'


const ClassPage = () => {
  const globalContext = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)

  const validClassCategory = () => {
    return categories.includes(window.location.pathname.replace('/', ''))
  }

  const handleFetchProducts = async () => {
    if (!globalContext.products[globalContext.classCategory]) {
      let response = await fetchProducts(globalContext.classCategory.toUpperCase())
      let favorites = await fetchFavorites()
      if (favorites) {
        let counts = {}
        favorites.data.forEach((favorite) => { counts[favorite.productId] = (counts[favorite.productId] || 0) + 1; })
        response.data = response.data.map((product) =>
          ({ ...product, favorites: counts[product._id] || 0 })
        )
        globalContext.setMyFavorites(favorites.data.filter((favorite) => favorite.userId === localStorage.getItem('username')).map((favorite) => { return favorite.productId }))
      }
      if (response.data) {
        const productsWithFormattedPrice = response.data.map((product) => { return { ...product, price: formatPrice(product.price) } })
        globalContext.setProducts({ ...globalContext.products, [globalContext.classCategory]: productsWithFormattedPrice })
        globalContext.setFilteredProducts({ ...globalContext.filteredProducts, [globalContext.classCategory]: productsWithFormattedPrice })
      }
    }
  }

  useEffect(() => {
    if (globalContext.classCategory) {
      handleFetchProducts()
    }
  }, [globalContext.classCategory])


  useEffect(() => {
    if (validClassCategory()) {
      globalContext.setClassCategory(window.location.pathname.replace('/', ''))
      //traer los productos de esa categoria y settear estilos.
    } else {
      console.log('no existe la categoria')
    }
  }, [])
  return (
    <>
      <Header page={globalContext.classCategory} />
      <Container classCategory={globalContext.classCategory}>
        <Title classCategory={globalContext.classCategory}>{globalContext.classCategory}</Title>
        {
          globalContext.classCategory && globalContext.filteredProducts[globalContext.classCategory] ?
            <>
              <Search classCategory={globalContext.classCategory} products={globalContext.filteredProducts[globalContext.classCategory].sort(function (a, b) { return a.price - b.price })} setFilteredProducts={(prods) => globalContext.setFilteredProducts({ ...globalContext.filteredProducts, [globalContext.classCategory]: prods })} />
              <Categories />
              <Products classCategory={globalContext.classCategory} totalProducts={globalContext.products[globalContext.classCategory].length} products={globalContext.filteredProducts[globalContext.classCategory].sort(function (a, b) { return a.price - b.price })} />
            </>
            :
            <LoadingContainer>
              <CircularProgress />
              cargando...
            </LoadingContainer>

        }
      </Container>
      <GuidedTour ready={globalContext.classCategory && globalContext.filteredProducts[globalContext.classCategory]} />
    </>
  )
}

export default ClassPage