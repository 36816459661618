import { CircularProgress, Skeleton, Switch } from "@mui/material"
import { useEffect, useState } from "react"
import Product from "../Product/Product"
import { getFavorites, getUserData, updateUserData } from "./Profile.actions"
import useStyles, { Banner, Container, FavoriteItem, FavoriteProducts, SectionTitle, Root, Section, Title, SectionDescription, OptionContainer, Description, Button } from './styles'
import Login from "../AuthGoogle/Login";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import { CustomAlert } from "../../styles"


const Profile = () => {
  const navigate = useNavigate();
  const [profileInfo, setProfileInfo] = useState(null)
  const classes = useStyles()
  const [favoritesProducts, setFavoritesProducts] = useState([])
  const [loginModal, setLoginModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState(null)
  const [userDataLoading, setUserDataLoading] = useState(false)
  const [onUpdateLoading, setOnUpdateLoading] = useState(false)
  const [customAlert, setCustomAlert] = useState({ show: false })


  const handleFavorites = async (userId) => {
    setLoading(true)
    const favorites = await getFavorites(userId)
    if (favorites) setFavoritesProducts(favorites)
    else {
      //handlear?
    }
    setLoading(false)
  }

  const handleUserData = async (userId) => {
    setUserDataLoading(true)
    const userData = await getUserData(userId)
    if (userData) setUserData(userData)
    else {
      console.log('user data fetch failed')
    }
    setUserDataLoading(false)
  }

  const handleInit = async () => {
    setProfileInfo(localStorage.getItem('user-email'))
    handleFavorites(localStorage.getItem('username'))
    handleUserData(localStorage.getItem('username'))
  }

  useEffect(() => {
    if (localStorage.getItem('user-email') && localStorage.getItem('username')) {
      handleInit()
    } else {
      setLoginModal(true)
    }
  }, [localStorage.getItem('user-email')])

  const onFailure = () => {
    navigate('')
  }

  const handleMailPreference = (emailType) => {
    setUserData({
      ...userData,
      [emailType]: !userData[emailType]
    })
  }

  const handleOnSaveConfiguration = async () => {
    setOnUpdateLoading(true)
    const response = await updateUserData(userData)
    if (response) {
      setCustomAlert({ ...customAlert, show: true, severity: 'success', message: 'Datos Actualizados!' })
    } else {
      setCustomAlert({ ...customAlert, show: true, severity: 'error', message: 'Ups, no se guardaron los datos. Reintentalo!' })
    }
    setOnUpdateLoading(false)
  }

  return (
    <Root>
      {
        profileInfo ?
          <>
            <Banner>
              <AccountCircleIcon fontSize="large" />
              <Title>
                Mi Perfil
              </Title>
            </Banner>
            <Container>
              <Section>
                <SectionTitle>
                  Favoritos
                </SectionTitle>
                {favoritesProducts.length === 0 ?
                  <SectionDescription>
                    Si guardas productos en favoritos, recibirás alertas de actualización de precio del mismo ;)
                  </SectionDescription>
                  :
                  <FavoriteProducts>
                    {
                      loading ?
                        Array.from(Array(6)).map((product, index) => {
                          return <Skeleton key={index} variant="rectangular" height={300} />
                        })
                        :
                        favoritesProducts.map((product, index) => (
                          <FavoriteItem>
                            <Product product={product} isFavorite={true} />
                          </FavoriteItem>
                        ))
                    }
                  </FavoriteProducts>}
              </Section>
              <Section>
                <SectionTitle>
                  Configuracion
                </SectionTitle>
                {userDataLoading && !userData ?
                  <CircularProgress className={classes.loader} />
                  :
                  <SectionDescription>
                    <OptionContainer>
                      <Description>Recibir actualizacion diaria de precios</Description>
                      <Switch
                        checked={userData.allEmails}
                        onChange={() => handleMailPreference('allEmails')}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </OptionContainer>
                    <OptionContainer>
                      <Description>Recibir actualizacion de precios de mis favoritos</Description>
                      <Switch
                        checked={userData.favoriteEmails}
                        onChange={() => handleMailPreference('favoriteEmails')}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </OptionContainer>
                    <Button onClick={handleOnSaveConfiguration}>
                      {
                        onUpdateLoading ?
                          <CircularProgress className={classes.loader} />
                          :
                          'Guardar'
                      }
                    </Button>
                  </SectionDescription>
                }
              </Section>
              {customAlert.show && <CustomAlert onClose={() => setCustomAlert({ show: false })} severity={customAlert.severity}>{customAlert.message}</CustomAlert>}
            </Container>
          </>
          :
          !loginModal && <CircularProgress className={classes.loader} />
      }
      {
        loginModal &&
        <div className={classes.loginModal}>
          <div className={classes.textCentered}>
            <Login setLoginModal={setLoginModal} onFailure={onFailure} />
          </div>
        </div>
      }
    </Root>
  )
}

export default Profile