import styled from "styled-components";

export const Container = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;
  @media (max-width: 500px){
    margin-top: 50px;
    margin-bottom: 40px;
  }
`

