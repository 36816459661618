import styled from 'styled-components'
import { FONT_ANANDA, FONT_LATO, FONT_POPPINS } from '../../../../theme'

export const Container = styled.div`
  height: 400px;
  margin-top: 100px;
  padding: 30px;
  background-color: #FF7F25;
  display: flex;
  @media (max-width: 500px){
    flex-direction: column;
    align-items: center;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  @media (max-width: 500px){
    width: 100%;
  }
`

export const ProductsContainer = styled.div`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: auto;
  width: 60%;
  column-gap: 10px;
  row-gap: 10px;
  @media (max-width: 500px){
    width: 100%;
    justify-content: center;
    margin-top: 5px;
  }
`
export const ProductWrapper = styled.div`

`

export const FirstText = styled.div`
  font-size: 23px;
  font-weight: 700;
  font-family: ${FONT_POPPINS};
  text-transform: uppercase;
  color: #ffffff;
  @media (max-width: 500px){
    font-size: 16px;
  }
`

export const SecondText = styled.div`
  font-size: 40px;
  font-weight: 600;
  font-family: ${FONT_LATO};
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  @media (max-width: 500px){
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    margin-top: 1px;
    margin-bottom: 1px;
  }
`

export const ThirdText = styled.div`
  font-size: 21px;
  font-weight: 200;
  font-family: ${FONT_POPPINS};
  color: #ffffff;
  @media (max-width: 500px){
    font-size: 15px;
  }
`

export const BlackText = styled.div`
  color: #000000;
`

