import styled, { css } from 'styled-components'
import theme, { FONT_POPPINS } from '../../theme'
import TimelineIcon from '@mui/icons-material/Timeline';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Pagination } from '@mui/material';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';

const handleColorTypeA = classCategory => {
  switch (classCategory) {
    case "electro":
      return "#3f3f3f";
    case "alimentos":
      return "#3f3f3f";
    default:
      return "#f6f6f6";
  }
};

const handleColorTypeB = classCategory => {
  switch (classCategory) {
    case "electro":
      return "#000000";
    case "alimentos":
      return "#000000";
    default:
      return "#ffffff";
  }
};

export const StyledTableRowsRoundedIcon = styled(TableRowsRoundedIcon)`
  margin-left: 5px;
  margin-right: 15px;
  margin-top: 10px;
  cursor: pointer;
  color: ${({ classCategory }) => handleColorTypeB(classCategory)};
`

export const StyledGridViewOutlinedIcon = styled(GridViewOutlinedIcon)`
  margin-left: 5px;
  margin-right: 15px;
  margin-top: 10px;
  cursor: pointer;
  color: ${({ classCategory }) => handleColorTypeB(classCategory)};
`

export const StyledTableRowsOutlinedIcon = styled(TableRowsOutlinedIcon)`
  margin-left: 5px;
  margin-right: 15px;
  margin-top: 10px;
  cursor: pointer;
  color: ${({ classCategory }) => handleColorTypeB(classCategory)};
`

export const StyledGridViewRoundedIcon = styled(GridViewRoundedIcon)`
  margin-left: 5px;
  margin-right: 15px;
  margin-top: 10px;
  cursor: pointer;
  color: ${({ classCategory }) => handleColorTypeB(classCategory)};
`

export const StyledPagination = styled(Pagination)`
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  & .MuiPaginationItem-root{
    color: ${({ classCategory }) => handleColorTypeB(classCategory)};
  }
`

export const Container = styled.div`
  //width: 100%;
`

export const Img = styled.img`
  width: 100%;
  height: 165px;
  border-radius: 15px;
  object-fit: contain;
  ${props => props.highlight && css`
    width: 100%;
    height: 70%;
    border-radius: 5px;
    object-fit: contain;
  `}
  ${props => props.singleModeView && props.highlight && css`
    width: 180px;
    height: 180px;
    object-fit: contain;
    border-radius: 10px;
  `}
`

export const Card = styled.div`
  position: relative;
  box-shadow: none;
  width: 100%;
  padding: 5px;
  padding-top: 20px;
  min-height: 300px;
  height: fit-content;
  background: ${theme.palette.white.main};
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 8%);
  text-align: center;
  ${props => !props.singleModeView && css`
    max-width: 250px;
  `}
  ${props => props.highlight && css`
    width: 325px;
    max-width: 325px;
    height: 392px;
    border-radius: 5px;
    padding: 0px;
  `}
  ${props => props.singleModeView && css`
    display: flex;
    flex-direction: row;
    min-height: fit-content;
    padding: 10px;
    border-radius: 10px;
  `}
  ${props => props.singleModeView && props.highlight && css`
    display: flex;
    flex-direction: row;
    height: fit-content;
    padding: 0px;
    border-radius: 10px;
  `}
  &:hover{
    cursor: pointer;
  };
`

export const Content = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  height: 55px;
  color: #000000;
  overflow-y: auto;
  font-family: ${FONT_POPPINS};
  text-transform: capitalize;
  margin-top: 3px;
  margin-bottom:3px;
  text-align: left;
  ${props => props.highlight && css`
    font-size: 18px;
    line-height: 18px;
    font-weight:200;
    color: #000000;
    text-align: center;
    margin-top:10px;
    padding-left: 10px;
    padding-right: 10px;
  `}
  ${props => props.singleModeView && css`
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    height: fit-content;
    overflow-y: unset;
  `}
  ${props => props.singleModeView && props.highlight && css`
    font-size:16px;
    font-weight:200;
    overflow: hidden;
    text-align: left;
    margin-top:5px;
    padding-left: 5px;
    padding-right: 5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `}

`

export const Price = styled(Title)`
  color: #505050;
  margin-top: 10px;
  height: fit-content;
  overflow: unset;
  ${props => props.singleModeView && css`
    font-size: 20px;
  `}
  ${props => props.highlight && css`
    font-size: 24px;
    line-height: 24px;
    font-weight:600;
    color: #FF5F00;
    position: absolute;
    top: 60%;
    left: 0;
    margin-left: 10px;
    background-color:#ffffffeb;
    border-radius:5px;
    padding:1px;
  `}
  ${props => props.singleModeView && props.highlight && css`
    color: #FF5F00;
    font-size:19px;
    line-height: 19px;
    font-weight:600;
    padding: 3px;
    position: inherit;
  `}
`

export const Favorites = styled.div`
  font-family: ${FONT_POPPINS};
  color: #000000;
  font-size: 16px;
  font-weight: 200;
  padding-right: 2px;
`

export const ProductsTitle = styled.div`
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  font-family: ${FONT_POPPINS};
  font-weight: bold;
  padding: 25px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  color: ${({ classCategory }) => handleColorTypeB(classCategory)};
`

export const NotFoundProducts = styled.div`
  font-family: ${FONT_POPPINS};
  font-size: 15px;
  color: ${({ classCategory }) => handleColorTypeA(classCategory)};
`

export const ProductsQuantity = styled(ProductsTitle)`
  font-weight: 200;
  padding: 0px;
  font-size: 16px;
  line-height: 22px;
  align-items: end;
  margin-left: 5px;
  color: ${({ classCategory }) => handleColorTypeB(classCategory)};
`

export const ChartTitle = styled.div`
  text-transform:uppercase;
  font-family:${FONT_POPPINS};
  font-weight:500;
  font-size:25px;
  padding:5px;
  color: ${theme.palette.text.blackLight};
  text-align:center;
  @media (max-width: 400px){
    font-size:18px;
  } 
`

export const Brand = styled.div`
  text-transform:uppercase;
  font-family:${FONT_POPPINS};
  font-weight:200;
  font-size:8px;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top:3px;
  background-color: #F26F3F;
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 30%);
  color: #F2F2F2;
  width: fit-content;
  margin-right: auto;
  margin-left: 10px;
  ${props => props.highlight && css`
    border-radius: 25px;
    padding: 0.5px 10px;
    position: absolute;
    font-size:18px;
    font-weight:500;
    top: 0px;
    right: 0px;
    margin-top: 5px;
    margin-right: 5px;
  `}
  ${props => props.singleModeView && props.highlight && css`
    border-radius: 25px;
    padding: 1px 10px;
    font-size:12px;
    font-weight:500;
    top: 0px;
    left: 0px;
    margin-top: 3px;
    margin-left: 3px;
  `}
`

export const GraphIcon = styled(TimelineIcon)`
  top: 0px;
  ${props => props.singleModeView && css`
    left:0px;
    margin-left: 5px;
  `}
  ${props => !props.singleModeView && css`
    right: 0px;
  `}
  margin-top: 10px;
  margin-right: 5px;
  position: absolute;
  border: solid 1px ${theme.palette.orange.main};
  background-color: ${theme.palette.white.main};
  color: ${theme.palette.orange.main};
  padding: 5px;
  border-radius: 999px;
  cursor: pointer;
  box-shadow: ${theme.shadows[5]};
  width: 30px !important;
  height: 30px !important;
  @media (max-width: 380px){
    width: 25px !important;
    height: 25px !important;
  };
`

const favIcon = css`
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
  color: ${theme.palette.orange.main};
  @media (max-width: 380px){
    width: 25px !important;
    height: 25px !important;
  }
`;

export const FavIcon = styled(FavoriteIcon)`
  ${favIcon}
`

export const FavBorderIcon = styled(FavoriteBorderIcon)`
  ${favIcon}
`

export const ProductDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${props => props.highlight && css`
    justify-content: center;
  `}
  ${props => props.singleModeView && props.highlight && css`
    justify-content: left;
  `}
`