import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import theme, { FONT_LATO } from '../../theme'

export const Root = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  min-height: 600;
  background: ${theme.palette.background.main};
`
export const Banner = styled.div`
  background: ${theme.palette.background.main};
  text-align: center;
`

export const SectionTitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 200;
  font-family: ${FONT_LATO};
  margin-bottom: 10px;
`

export const Title = styled.div`
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  font-family: ${FONT_LATO};
  text-transform: capitalize;
  text-align: center;
`

export const Section = styled.div`
  margin-bottom: 50px;
`

export const Container = styled.div`
  min-height: 100vh;
  padding: 10px;
  margin-top: 40px;
`

export const FavoriteProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 5px;
  row-gap: 5px;
`

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  column-gap: 30px;
  @media (max-width: 400px){
    padding: 5px 20px;
  }
`

export const Description = styled.div`
  text-align: center;
  font-family: 'Spartan';
  font-weight: 200;
  font-size: 16px;
  @media (max-width: 400px){
    text-align: left;
    font-size: 12px;
    max-width: 80%;
  }
`

export const Button = styled.div`
  font-family: 'Spartan';
  font-weight: 200;
  font-size: 1rem;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  transition: 0.4s ease;
  width: fit-content;
  padding: 5px;
  margin: 10px auto 0px auto;
  @media (max-width: 400px){
    margin: 20px auto 0px auto;
    font-size: 0.9rem;
  }
  :hover {
    cursor: pointer;
    font-size: 1.1em;
    transition: 0.4s ease;
  }
`
export const FavoriteItem = styled.div`
  max-width: 175px;
`

export const SectionDescription = styled.div`
`

const useStyles = makeStyles(theme => ({
  favoritesTitle: {
    textTransform: 'UPPERCASE',
    textAlign: 'center',
    fontFamily: 'Spartan',
    fontWeight: 400,
    fontSize: 22,
    '@media (max-width: 400px)': {
      fontSize: 20,
    },
    margin: 5,
    marginTop: 15,
    marginBottom: 20
  },
  loginModal: {
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: 'fit-content',
    height: 'fit-content',
  },
  loginModalTitle: {
    fontFamily: 'Spartan',
    color: theme.palette.text.black,
    fontSize: 20,
    fontWeight: 200,
    padding: 20,
    marginBottom: 10,
    '@media (max-width: 400px)': {
      fontSize: 16,
      fontWeight: 400,
    }
  },
  textCentered: {
    ...theme.textCentered,
    padding: 5,
    paddingBottom: 15
  },
  loader: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

export default useStyles