import styled from 'styled-components'
import theme, { FONT_POPPINS } from '../../../theme'

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
`

export const Container = styled.div`
  height: 450px;   
  padding: 50px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  @media (max-width: 500px){
    flex-direction: column;
    padding: 20px;
    height: fit-content;
  }
`

export const LeftContent = styled.div`
  width: 60%;
  @media (max-width: 500px){
    width: 100%;
    z-index: 10;
  }
`

export const RightContent = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px){
    position: absolute;
    width: 93%;
    justify-content: flex-end;
    z-index: 1;
    opacity: 0.2;
  }
`

export const Image = styled.img`
  width: 350px;
  height: 350px;
  border-radius: 9999px;
  margin: 12px;
  background-color: ${theme.palette.orange.medium};
  object-fit: contain;
  @media (max-width: 500px){
    width: 250px;
    height: 250px;
  }
  @media (max-width: 830px){
    width: 275px;
    height: 275px;
  }
`

export const Border = styled.div`
  border-radius: 9999px;
  border: 8px solid ${theme.palette.orange.strong};
`

export const Title = styled.div`
  font-family: ${FONT_POPPINS};
  color: #ffffff;
  font-weight: 700;
  font-size: 65px;
  @media (max-width: 500px){
    font-size: 45px;
  }
`

export const Subtitle = styled(Title)`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  @media (max-width: 500px){
    text-align:left;
  }
`

export const Highligth = styled(Title)`
  color: transparent;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #F49958;
`