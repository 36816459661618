import styled from 'styled-components'
import { FONT_POPPINS } from '../../../theme'

export const Container = styled.div`
  padding-bottom: 40px;
`

export const Title = styled.div`
  font-family: ${FONT_POPPINS};
  font-size: 14px;
  font-weight: 200;
  text-align: center;
  height: 35%;
  display: flex;
  align-items: center;
  padding: 5px;
`

export const Image = styled.img`
  width: 100%;
  height: 65%;
`
export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 50px;
  row-gap: 50px;
  margin-top: 40px;
  @media (max-width: 500px){
    margin-top: 20px;
  }
`

export const CardContainer = styled.div`
  background-color: #ffffff;
  width: 250px;
  height: 310px;
  border-radius: 10px;
`