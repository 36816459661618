import { categories } from "../../../App"
import HomeCategory from "./category"
import { CategoriesContainer, CategoriesTitle, Container } from "./styles"
import { useNavigate } from 'react-router-dom';



const HomeCategories = () => {
  let navigate = useNavigate();
  const mapCategoriesNames = (category) => {
    switch (category) {
      default:
        return category
    }
  }

  const handleOnClick = (category) => {
    navigate(`/${category}`);
  }
  const mapCategories = () => {
    return (
      categories.map((category) => {
        return (
          <div onClick={() => handleOnClick(category)}>
            <HomeCategory title={mapCategoriesNames(category)} />
          </div>
        )
      })
    )
  }
  return (
    <Container>
      <CategoriesTitle>Categorias</CategoriesTitle>
      <CategoriesContainer>
        {mapCategories()}
      </CategoriesContainer>
    </Container>

  )
}

export default HomeCategories