import { Grid, Pagination, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { createRef, useContext, useEffect, useState } from 'react'
import Product from './Product'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import { NotFoundProducts, ProductsQuantity, ProductsTitle, StyledGridViewOutlinedIcon, StyledGridViewRoundedIcon, StyledPagination, StyledTableRowsOutlinedIcon, StyledTableRowsRoundedIcon } from './styles'
import RangeSlider from '../Search/RangeSlider/RangeSlider'
import { GlobalContext } from '../../App'

const useStyles = makeStyles(theme => ({
  root: {
    width: 'inherit !important',
    marginTop: 'inherit !important',
    marginLeft: 'inherit !important',
    display: 'flex',
    justifyContent: 'space-evenly',
    rowGap: 10,
    textAlign: '-webkit-center'
  },
  media: {
    height: 140,
  },
  title: {
    ...theme.title
  },
  divider: {
    ...theme.divider
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconViewContainer: {
    marginRight: 20,
    '@media (min-width: 400px)': {
      display: 'none'
    }
  }
}))

export const formatPrice = (price) => {
  const splittedPrice = price.split('.')
  if (splittedPrice[splittedPrice.length - 1].length === 2) {
    let splittedPriceByDot = price.split('.')
    let twoDigitsAfterDot = splittedPriceByDot.pop()
    let formattedPrice = `${splittedPriceByDot[0].replace(/,/g, '').toString()}.${twoDigitsAfterDot}`
    return parseFloat(parseFloat(formattedPrice).toFixed(2))
  }
  return parseFloat(parseFloat(price.replace(/\./g, '').replace(/,/g, '.')).toFixed(2));
}

const Products = ({ totalProducts, products, classCategory }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [viewOnSingleColumn, setViewOnSingleColumn] = useState(false)
  const [xs, setXs] = useState(5)
  const [productsPaginated, setProductsPaginated] = useState([])
  const productsPerPage = 20
  const [pagesQuantity, setPagesQuantity] = useState(0)
  const productsTopRef = createRef()
  const [pageNumber, setPageNumber] = useState(null)
  const [showRange, setShowRange] = useState(false)
  const globalContext = useContext(GlobalContext)

  useEffect(() => {
    if (totalProducts > globalContext.filteredProducts[globalContext.classCategory].length && globalContext.filteredProducts[globalContext.classCategory].length !== 0) {
      setShowRange(true)
    } else {
      setShowRange(false)
    }
  }, [globalContext.filteredProducts[globalContext.classCategory]])


  const handleOnChangeView = (value) => {
    setViewOnSingleColumn(value)
    if (!value) {
      setXs(5)
    } else {
      setXs(10)
    }
  }

  useEffect(() => {
    handlePagination(1)
    setPageNumber(1)
    setPagesQuantity(Math.ceil(products.length / 20))
  }, [products])

  const getRangePrice = () => {
    const minValue = globalContext.categorizedProducts[globalContext.classCategory].reduce(function (prev, curr) {
      return prev.price < curr.price ? prev : curr;
    });
    const maxValue = globalContext.categorizedProducts[globalContext.classCategory].reduce(function (prev, curr) {
      return prev.price > curr.price ? prev : curr;
    });
    globalContext.setRangePrice({ min: minValue.price, max: maxValue.price })
  }

  useEffect(() => {
    if (globalContext.categorizedProducts[globalContext.classCategory] && globalContext.categorizedProducts[globalContext.classCategory].length) {
      getRangePrice()
    }
  }, [globalContext.selectedCategory, globalContext.categorizedProducts[globalContext.classCategory]])

  const handlePagination = (page) => {
    setPageNumber(page)
    setProductsPaginated(products.slice(productsPerPage * (page - 1), productsPerPage * page))
    if (page > 1 || pageNumber > 1) {
      productsTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const showProductsWithMaxPrice = (priceTuple) => {
    return globalContext.categorizedProducts[globalContext.classCategory].filter((prod) => prod.price >= priceTuple[0] && prod.price <= priceTuple[1])
  }

  const handleSetFilteredProductsByPrice = (filteredProductsByPrice) => {
    globalContext.setFilteredProducts({ ...globalContext.filteredProducts, [globalContext.classCategory]: globalContext.categorizedProducts[globalContext.classCategory].filter((prod) => filteredProductsByPrice.includes(prod._id)) })
  }


  return (
    <>
      {showRange && globalContext.rangePrice.max && globalContext.rangePrice.min &&
        <RangeSlider
          onChange={(price) => showProductsWithMaxPrice(price)}
          setFilteredProducts={(prods) => handleSetFilteredProductsByPrice(prods)}
        />}
      <div className={classes.headerContainer}>
        <ProductsTitle classCategory={classCategory} ref={productsTopRef}>
          {
            totalProducts === globalContext.filteredProducts[globalContext.classCategory].length ?
              'Todos' :
              'Resultados'
          }
          {
            globalContext.filteredProducts[globalContext.classCategory].length > 0 &&
            <ProductsQuantity classCategory={globalContext.classCategory}>
              ({globalContext.filteredProducts[globalContext.classCategory].length})
            </ProductsQuantity>
          }
        </ProductsTitle>
        {
          viewOnSingleColumn ?
            <div className={classes.iconViewContainer}>
              <StyledTableRowsRoundedIcon fontSize={'medium'} classCategory={globalContext.classCategory} />
              <StyledGridViewOutlinedIcon onClick={() => handleOnChangeView(false)} fontSize={'medium'} classCategory={globalContext.classCategory} />
            </div>
            :
            <div className={classes.iconViewContainer}>
              <StyledTableRowsOutlinedIcon onClick={() => handleOnChangeView(true)} fontSize={'medium'} classCategory={globalContext.classCategory} />
              <StyledGridViewRoundedIcon fontSize={'medium'} classCategory={globalContext.classCategory} />
            </div>
        }
      </div>
      <Grid container rowSpacing={1} columns={{ xs: 11, sm: 17, md: 20 }} className={classes.root}>
        {
          loading ?
            Array.from(Array(6)).map((product, index) => (
              <Grid item xs={xs} sm={4} md={3} key={index}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
            ))
            :
            productsPaginated.length === 0 ?
              <NotFoundProducts classCategory={[globalContext.classCategory]}>No se encontraron productos relacionados {':('}</NotFoundProducts>
              :
              productsPaginated.map((product, index) => (
                <Grid item xs={xs} sm={4} md={3} key={index}>
                  <Product singleModeView={viewOnSingleColumn} product={product} isFavorite={globalContext.myFavorites.includes(product._id)} />
                </Grid>
              ))
        }
      </Grid>
      {pageNumber && productsPaginated.length !== 0 &&
        <StyledPagination
          classCategory={classCategory}
          onChange={(e, page) => handlePagination(page)}
          count={pagesQuantity}
          variant="outlined"
          color="primary"
          page={pageNumber}
        />
      }
    </>
  )
}
export default Products