import { HomeContainer, Title } from "../styles"
import Card from "./Card"
import { CardsContainer, Container } from "./styles"
import image1 from '../../../assets/images/howItWorks/1.png'
import image2 from '../../../assets/images/howItWorks/2.png'
import image3 from '../../../assets/images/howItWorks/3.png'

const HowItWorks = () => {

  return (
    <HomeContainer>
      <Container>
        <Title>Como Funciona</Title>
        <CardsContainer>
          <Card title={'Nuestro motor recompila la informacion de todos los productos de tiendas verificadas'} imageSrc={image1} />
          <Card title={'Almacenamos y categorizamos los productos para simplificarte la busqueda'} imageSrc={image2} />
          <Card title={'Encontrá la tienda que vende el producto que estas buscando, al mejor precio'} imageSrc={image3} />
        </CardsContainer>
      </Container>
    </HomeContainer>
  )
}

export default HowItWorks