import { Alert, Box, Button, Card, CardContent, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, OutlinedInput, Tab, Tabs, TextField, Typography } from "@mui/material";
import GoogleLogin from "react-google-login";
import { postUser } from "./Login.actions";
import { useState } from "react";
import { Auth } from "aws-amplify";
import RegisterForm from "./RegisterForm";
import { useEffect } from "react";

const Login = ({ onFailure, setLoginModal }) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(0);
  const [msg, setMsg] = useState(null)
  const [showVerificationCode, setShowVerificationCode] = useState(false)
  const [username, setUsername] = useState(null)
  const [values, setValues] = useState({
    usernameLogin: '',
    usernameRegister: '',
    passwordLogin: '',
    passwordRegister: '',
    confirmationPassword: '',
    showPassword: false
  })

  function deleteError() {
    setMsg(null)
  }

  useEffect(() => {
    if (msg) {
      setTimeout(deleteError, 2000);
    }
  }, [msg])

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }
  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const onSubmitLogin = async (e) => {
    setLoading(true)
    e.preventDefault()
    let username = e.target["usernameLogin"].value
    let password = e.target["passwordLogin"].value
    if (username && password) {
      try {
        const user = await Auth.signIn(username, password);
        localStorage.setItem('user-email', user.attributes.email)
        localStorage.setItem('username', user.attributes.sub)
        setLoginModal(false)
      } catch (error) {
        getErrorByCode(error.code)
      }
    }
    setLoading(false)
  }

  const validInputs = (username, password, confirmationPassword) => {
    if (password !== confirmationPassword) {
      setMsg({ severity: 'error', message: 'Las contraseñas no coinciden' })
      return false
    }
    return true
  }

  const getErrorByCode = (code) => {
    switch (code) {
      case 'UsernameExistsException':
        setMsg({ severity: 'warning', message: 'Ya existe un usuario registrado con ese email' })
        break;
      case 'NotAuthorizedException':
        setMsg({ severity: 'warning', message: 'Contraseña o Usuario Invalido' })
        break;
      default:
        setMsg({ severity: 'warning', message: 'Ocurrió un error al intentar registrarse, revisa los datos ingresados' })
        break;
    }
  }

  const onSuccess = () => {
    handleChangeTab(null, 0)
    setMsg(null)
  }

  const handleOnSuccess = async () => {
    setMsg({ severity: 'success', message: 'Usuario Creado con Exito!!' })
    setTimeout(onSuccess, 2000);
  }


  const onSubmitVerificationCode = async (code) => {
    try {
      await Auth.confirmSignUp(username, code);
      await handleOnSuccess()
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }

  const onSubmitRegister = async (username, password, confirmationPassword) => {

    if (validInputs(username, password, confirmationPassword)) {
      if (username && password) {
        try {
          let response = await Auth.signUp({
            username: username,
            password: password,
            attributes: {
              "email": username,
            },
          });
          await postUser(response.userSub, response.user.username)
          setUsername(response.user.username)
          setShowVerificationCode(true)
        } catch (e) {
          getErrorByCode(e.code)
        }

      }
    }

  };



  const handleOnFailure = (response) => {
    onFailure()
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    if (event) {
      event.preventDefault()
    }
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Ingresar" {...a11yProps(0)} />
          <Tab label="Registrarse" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box className='content-center' style={{ marginTop: 50, padding: 10, maxWidth: 500 }}>
          <Card sx={{ zIndex: 1 }}>
            <CardContent sx={{ padding: "12px 9px 7px !important" }}>
              <Box sx={{ mb: 6 }}>
                <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
                  Inicie Sesion
                </Typography>
                <Typography variant='body2'>Ingrese sus credenciales</Typography>
              </Box>
              <form noValidate autoComplete='off' onSubmit={onSubmitLogin}>
                <TextField fullWidth id='usernameLogin' label='Usuario' sx={{ marginBottom: 4 }} />
                <TextField fullWidth id='passwordLogin' label='Contraseña' sx={{ marginBottom: 2 }} />
                {loading ?
                  <LinearProgress style={{ margin: '0px 0px' }} />
                  :
                  <Button
                    fullWidth
                    size='large'
                    variant='contained'
                    sx={{ marginBottom: 7, marginTop: 7 }}
                    type='submit'
                  >
                    Ingresar
                  </Button>}
              </form>
            </CardContent>
          </Card>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RegisterForm
          onSubmitRegister={onSubmitRegister}
          showVerificationCode={showVerificationCode}
          onSubmitVerificationCode={onSubmitVerificationCode}
        />
      </TabPanel>
      {msg && <Alert severity={msg.severity}>{msg.message}</Alert>}
    </div>
  )
}

export default Login