import Categories from "./Components/Category/Categories";
import { createContext, useState } from "react";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Faq from "./Components/Faq/Faq";
import Profile from "./Components/Profile/Profile";
import { Container, HomeContainer, Title } from "./styles";
import EngagementLayout from "./Components/EngagementLayout/EngagementLayout";
import Highlight from "./Components/Highlight";
import BrandCarousel from "./Components/BrandCarousel";
import ClassPage from "./Components/Class";
import HomeCategories from "./Components/Category/HomeCategories";
import { fetchHomeProducts } from "./Components/Product/Product.actions";
import { useEffect } from "react";
import Amplify from "aws-amplify";
import { COGNITO } from "./configs/aws";
import Landing from "./Components/Landing";

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

export const GlobalContext = createContext(null)

//['electro', 'alimentos', 'gaming']
export const categories = ['electro', 'gaming']


const App = ({ eventTrackGA }) => {
  const [products, setProducts] = useState({})
  const [classCategory, setClassCategory] = useState(null)
  const [filteredProducts, setFilteredProducts] = useState({})
  const [categories, setCategories] = useState({})
  const [favorites, setFavorites] = useState([])
  const [rangePrice, setRangePrice] = useState({ min: null, max: null })
  const [filteredProduct, setFilteredProduct] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [categorizedProducts, setCategorizedProducts] = useState({})
  const [productLabels, setProductLabels] = useState([])
  const [homeProducts, setHomeProducts] = useState({})
  const [myFavorites, setMyFavorites] = useState([])


  const onInit = async () => {
    let response = await fetchHomeProducts()
    setHomeProducts(response.data)
  }

  useEffect(() => {
    onInit()
  }, [])
  //TODO USAR [products, setProducts] DESDE EL GLOBAL CONTEXT
  return (
    <BrowserRouter>
      <GlobalContext.Provider value={{
        rangePrice: rangePrice,
        setRangePrice: setRangePrice,
        filteredProduct: filteredProduct,
        setFilteredProduct: setFilteredProduct,
        setSelectedCategory: setSelectedCategory,
        selectedCategory: selectedCategory,
        categorizedProducts: categorizedProducts,
        setCategorizedProducts: setCategorizedProducts,
        eventTrackGA: eventTrackGA,
        products: products,
        setProducts: setProducts,
        setProductLabels: setProductLabels,
        productLabels: productLabels,
        filteredProducts: filteredProducts,
        setFilteredProducts: setFilteredProducts,
        setClassCategory: setClassCategory,
        classCategory: classCategory,
        setCategories: setCategories,
        categories: categories,
        favorites: favorites,
        setFavorites: setFavorites,
        myFavorites: myFavorites,
        setMyFavorites: setMyFavorites,
        homeProducts: homeProducts
      }}>

        <Routes>
          <Route exact path="/" element={
            <>
              <Header page={'home'} />
              <Landing />
              <Footer page={'home'} />
            </>
          } />
          < Route path="/:classSelected" element={
            <>
              <ClassPage />
              <Footer />
            </>
          } />
          < Route exact path='/faqs' element={
            <>
              <Header />
              <Faq />
              <Footer />
            </>
          } />

          < Route exact path='/profile' element={
            <>
              <Header />
              <Profile />
              <Footer />
            </>
          } />

          {/*<Route exact path='/prueba' element={
            <PruebaTs />
          } />*/}
        </Routes >
      </GlobalContext.Provider >
    </BrowserRouter >
  );
}

export default App;
