import "react-multi-carousel/lib/styles.css";
import { Img, Item, StyledCarousel } from "./styles";
import CarouselItem from './CarouselItem/index'
import React from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import banner1 from '../../assets/images/banner/banner1.png'
import banner2 from '../../assets/images/banner/banner2.png'
import banner3 from '../../assets/images/banner/banner3.png'


const items = [
  <CarouselItem bannerSrc={banner1} subtitle={'Con el motor de busqueda de COMPAR-E ahorrá tiempo y dinero'} />,
  <CarouselItem bannerSrc={banner2} subtitle={'Obtenemos los productos de tiendas verificadas por nosotros'} />,
  <CarouselItem bannerSrc={banner3} subtitle={'Guarda en favoritos un producto para recibir un mail cuando su precio se actualice'} />,
];


const BrandCarousel = () => {
  return (
    <StyledCarousel
      autoPlayInterval={6000}
      autoPlay={true}
      infinite={true}
      disableButtonsControls={true}
      disableDotsControls={true}
      mouseTracking
      items={items}
    />
  )
}
export default BrandCarousel