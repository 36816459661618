import styled, { css } from 'styled-components'
import { FONT_LATO } from '../../theme'

const handleColorType = classCategory => {
  switch (classCategory) {
    case "gaming":
      return "#ffffff";
    default:
      return "#3f3f3f";
  }
};

export const Title = styled.div`
  font-size: 24px;
  line-height: 30px;
  color: ${({ classCategory }) => handleColorType(classCategory)};
  font-family: ${FONT_LATO};
  font-weight: 500;
  padding: 25px 25px 5px 10px;
`

export const CategoryTitle = styled.div`
  font-family: ${FONT_LATO};
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`
export const CategoryImg = styled.img.attrs({ alt: 'category-icon' })`
  background-color: #F2F2F2;
  width: 50px;
  height: 50px;
  padding: 13px;
  border-radius: 10px;
`
export const IconContainer = styled.div`
  background-color: #F2F2F2;
  width: 50px;
  height: 50px;
  padding: 13px;
  border-radius: 10px;
`

export const CategoryContainer = styled.div`
  width: fit-content;
  height: fit-content;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
  border: solid 1px #797979;
  display: flex;
  column-gap: 5px;
  align-items: center;
  background-color: #ffffff;
  &:hover{
    cursor: pointer;
  };
  ${props => props.isSelectedCategory && css`
    background-color:#b8b8b8;
  `}
`

export const CategoriesContainer = styled.div`
  overflow: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 10px;
`
