import axios from "axios";

export const fetchCategories = async (classCategory) => {
  let data;
  try {
    data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/categories/${classCategory}`,
      {
        headers: {
          'x-api-key': "apikey"
        }
      });

  } catch (error) {
    console.log(error.message);
  }
  return data;
}