import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdbreact";
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { makeStyles } from '@mui/styles'
import FooterForm from "./FooterForm";
import { Link } from "react-router-dom";
import { AccordionTitle, Container, FooterContainer, Section, SectionContent, SectionTitle, StyledAccordion, StyledLink, Wrapper } from "./styles";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FONT_POPPINS } from "../../theme";



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#ffffff",
    textAlign: 'center',
    boxShadow: '0px -1px 15px -5px rgb(0 0 0 / 45%)',
  },
  faqSection: {
    color: '#000000',
    fontWeight: 600,
    fontSize: 18,
    fontFamily: FONT_POPPINS,
    textTransform: 'uppercase',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
      color: '#000000',
      fontWeight: 500,
    }
  },
  pressElement: {
    textDecoration: 'underline',
    '&:hover': {
      fontWeight: 700,
    }
  }
}))
const Footer = ({ page = null }) => {
  const classes = useStyles()

  return (
    <Container page={page}>
      <MDBContainer className='p-4'>
        <section className='mb-4'>
          <a
            className='btn btn-primary btn-floating m-1'
            style={{ backgroundColor: '#ac2bac' }}
            href='https://www.instagram.com/devthion/'
            role='button'
          >
            <MDBIcon fab icon='instagram' />
          </a>
          <a
            className='btn btn-primary btn-floating m-1'
            style={{ backgroundColor: '#0082ca' }}
            href='https://www.linkedin.com/in/devthion-enterprise-803949228/'
            role='button'
          >
            <MDBIcon fab icon='linkedin-in' />
          </a>
          <a
            className='btn btn-primary btn-floating m-1'
            style={{ backgroundColor: '#0082ca' }}
            href='https://twitter.com/DiegoVivona'
            role='button'
          >
            <MDBIcon fab icon='twitter' />
          </a>
        </section>
        <Wrapper>
          <FooterContainer>
            <FooterForm />
          </FooterContainer>
          <Section>
            <SectionTitle>Prensa</SectionTitle>
            <SectionContent>
              <StyledLink href="https://www.ambito.com/informacion-general/productos/investigo-innovo-y-creo-una-web-que-te-permite-encontrar-el-mejor-precio-del-producto-buscado-n5491682">
                Ámbito Financiero
              </StyledLink>
              <StyledLink href="https://www.gustavosylvestre.com/somos-un-intermediario-entre-el-usuario-y-las-grandes-marcas-dijo-diego-vivona-creador-del-sitio-web-compar-e/">
                Gustavo Sylvestre
              </StyledLink>
              <StyledLink href="https://radioconvos.com.ar/compar-e-la-pagina-web-que-te-permite-encontrar-el-mejor-precio-del-producto-buscado/">
                Radio con vos
              </StyledLink>
              <StyledLink href="https://estacionplus.com.ar/contenido/51931/investigo-innovo-y-creo-una-web-que-te-permite-encontrar-el-mejor-precio-del-pro">
                Estación Plus
              </StyledLink>
              <StyledLink href="https://ar.radiocut.fm/audiocut/diego-vivona-proyectamos-agregar-electrodomesticos-y-sector-inmobiliario/">
                Radio Cut
              </StyledLink>
              <StyledLink href="https://villadelparqueinfo.com.ar/noticia/16696/vecinos-destacados-diego-vivona-el-joven-de-villa-del-parque-que-creo-una-web-que-permite-encontrar-el-mejor-precio-del-producto-buscado.html">
                Villa del parque info
              </StyledLink>
            </SectionContent>
          </Section>
          <Link className={classes.faqSection} to="/faqs">Preguntas Frecuentes</Link>
        </Wrapper>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2022 Copyright: {' '}
        <a className='text-white' href='http://www.devthion.com/'>
          DEVTHION
        </a>
      </div>
    </Container>
  )
}

export default Footer