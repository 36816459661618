import styled, { css } from 'styled-components'
import theme, { FONT_LATO, FONT_POPPINS } from '../../theme'
import Drawer from '@mui/material/Drawer';


export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    margin-top: 50px;
    background-color: #2C2927;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    text-align-last: center;
    & .MuiTypography-root {
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      font-family: ${FONT_POPPINS};
    }
  }
`

export const Container = styled.div`
  background-color: ${theme.palette.background.main};
  height: 50px;
  width: '100%';
  ${props => props.page === 'home' && css`
    background-color: #FFDBA9;
    color: #1E1E1E;
    box-shadow: inset 0px 0px 1px 0px #000000;
  `}
  ${props => props.page === 'gaming' && css`
    background-color: #04005e;
    color: #ffffff
  `}
`

export const Title = styled.div`
  text-transform: uppercase;
  font-family: ${FONT_LATO};
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-decoration: none;
  padding-left: 5px;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  };
  @media (max-width: 400px){
    padding-left: 0px
  }
`