import { useContext } from "react"
import { GlobalContext } from "../../../../App"
import Product from "../../../Product/Product"
import { Title } from "../../styles"
import { Container } from "../styles"
import { ProductsContainer, ProductWrapper } from "./styles"

const PopularProducts = () => {
  const globalContext = useContext(GlobalContext)

  const mapProducts = () => {
    return (
      globalContext.homeProducts['populars'] && globalContext.homeProducts['populars'].map((product, index) => (
        <ProductWrapper>
          <Product singleModeView={false} product={product} isFavorite={false} highlight={true} />
        </ProductWrapper>
      ))
    )
  }
  return (
    <Container>
      <Title>Productos Populares</Title>
      <ProductsContainer>
        {mapProducts()}
      </ProductsContainer>
    </Container>
  )
}

export default PopularProducts