import { BackgroundImage, Border, Container, Highligth, Image, LeftContent, RightContent, Subtitle, Title } from "./styles"
import Background from '../../../assets/images/Pattern/Hero.svg'

const CarouselItem = ({ bannerSrc, subtitle }) => {
  return (
    <Container>
      <BackgroundImage src={Background} />
      <LeftContent>
        <Title>
          Encontrá el <Highligth>mejor precio</Highligth> para tu producto
        </Title>
        <Subtitle>
          {subtitle}
        </Subtitle>
      </LeftContent>
      <RightContent>
        <Border>
          <Image src={bannerSrc} />
        </Border>
      </RightContent>
    </Container>
  )
}

export default CarouselItem