import styled, { css } from "styled-components";
import { MDBFooter } from "mdbreact";
import { FONT_POPPINS } from '../../theme'


export const Container = styled(MDBFooter)`
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0px -5px 15px -5px rgb(0 0 0 / 45%);
  ${props => props.page === 'home' && css`
    background-color: #FF7F26;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 400px){
    flex-direction: column;
  }
`

export const FooterContainer = styled.div`
  margin-bottom: 50px;
  width: 50%;
  @media (max-width: 400px){
    flex-direction: column;
    width: inherit;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 400px){
    align-items: center;
    margin-bottom: 40px;
  }
`

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 400px){
    align-items: center;
  }
`

export const SectionTitle = styled.div`
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  font-family: ${FONT_POPPINS};
`

export const StyledLink = styled.a`
  text-decoration: underline;
  color: #1e1e1e;
  padding: 3px 3px 3px 0px;
`