import axios from "axios";

export const getFavorites = async (userId) => {
  let data = null;
  try {
    data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/favorites/${userId}`,
      {
        headers: {
          'x-api-key': "apikey"
        }
      });
    data = data.data
  } catch (error) {
    console.log(error.message);
  }
  return data;
}

export const getUserData = async (userId) => {
  let data = null;
  try {
    data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/user/data/${userId}`,
      {
        headers: {
          'x-api-key': "apikey"
        }
      });
    data = data.data
  } catch (error) {
    console.log(error.message);
  }
  return data;
}

export const updateUserData = async (userData) => {
  let data = null;
  try {
    data = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/data/${userData.userId}`,
      {
        userData: {
          allEmails: userData.allEmails,
          favoriteEmails: userData.favoriteEmails
        }
      },
      {
        headers: {
          'x-api-key': "apikey"
        }
      });
    data = data.data
  } catch (error) {
    console.log(error.message);
  }
  return data;
}