import { CategoryContainer, CategoryTitle, Image, ImageContainer } from "./styles"
import Electronics from '../../../assets/images/HomeCategories/Electronics.svg'
import Gaming from '../../../assets/images/HomeCategories/Gaming.svg'
import Food from '../../../assets/images/HomeCategories/Food.svg'
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';

const HomeCategory = ({ title }) => {
  const mapIcon = () => {
    switch (title) {
      case 'gaming':
        return (
          <ImageContainer>
            <Image src={Gaming} />
          </ImageContainer>
        )
      case 'electro':
        return (
          <ImageContainer>
            <Image src={Electronics} />
          </ImageContainer>
        )
      case 'alimentos':
        return (
          <ImageContainer>
            <Image src={Food} />
          </ImageContainer>
        )
      default:
        return (
          <ImageContainer>
            <HourglassDisabledIcon />
          </ImageContainer>
        )
    }
  }

  return (
    <CategoryContainer>
      {mapIcon()}
      <CategoryTitle>{title}</CategoryTitle>
    </CategoryContainer>
  )
}

export default HomeCategory