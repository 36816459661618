import GraphicCardIcon from '../../assets/images/categorias/graphicCard.png'
import ProcessorIcon from '../../assets/images/categorias/Processor.png'
import HardDiskIcon from '../../assets/images/categorias/hardDisk.png'
import PowerIcon from '../../assets/images/categorias/power.png'
import MotherboardIcon from '../../assets/images/categorias/Motherboard.png'
import FanIcon from '../../assets/images/categorias/Fan.png'
import CoolingIcon from '../../assets/images/categorias/cooling.png'
import MonitorIcon from '../../assets/images/categorias/Monitor.png'
import RamIcon from '../../assets/images/categorias/Ram.png'
import CPUIcon from '../../assets/images/categorias/CPU.png'
import KitchenIcon from '@mui/icons-material/Kitchen';
import KitchenSharpIcon from '@mui/icons-material/KitchenSharp';
import TvIcon from '@mui/icons-material/Tv';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import HvacIcon from '@mui/icons-material/Hvac';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import SpeakerIcon from '@mui/icons-material/Speaker';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import AdfScannerOutlinedIcon from '@mui/icons-material/AdfScannerOutlined';
import WineBarIcon from '@mui/icons-material/WineBar';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';

import { CategoryContainer, CategoryTitle, CategoryImg, IconContainer } from './styles'
import { useContext, useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { GlobalContext } from '../../App'


const Category = ({ category, index, handleOnClick, isSelectedCategory }) => {

  const mapIcons = (category) => {
    switch (category) {
      case 'Microprocesadores':
        return ProcessorIcon
      case 'Almacenamiento':
        return HardDiskIcon
      case 'Placas de Video':
        return GraphicCardIcon
      case 'Fuentes de Alimentación':
        return PowerIcon
      case 'Motherboards':
        return MotherboardIcon
      case 'Coolers':
        return FanIcon
      case 'Refrigeracion':
        return CoolingIcon
      case 'Monitores':
        return MonitorIcon
      case 'Memorias Ram':
        return RamIcon
      case 'Gabinetes':
        return CPUIcon
      case 'Heladeras':
        return <KitchenIcon />
      case "Freezers":
        return <KitchenSharpIcon />
      case "Televisores":
        return <TvIcon />
      case "Pequeños Electrodomesticos":
        return <CoffeeMakerIcon />
      case 'Hornos':
        return <HvacIcon />
      case "Ventiladores":
        return <HeatPumpIcon />
      case "Termotanques y Calefones":
        return <GasMeterIcon />
      case "Audio":
        return <SpeakerIcon />
      case "Aire Acondicionado":
        return <AcUnitIcon />
      case "Microondas":
        return <MicrowaveIcon />
      case "Reproductores":
        return <OndemandVideoIcon />
      case "Lavado":
        return <LocalLaundryServiceIcon />
      case "Aspiradoras":
        return <CleaningServicesIcon />
      case "Calefaccion":
        return <SolarPowerIcon />
      case "Campanas":
        return <AdfScannerOutlinedIcon />
      case "Cavas":
        return <WineBarIcon />
      default:
        return null
    }
  }

  const placeholderRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const globalContext = useContext(GlobalContext)

  useEffect(() => {
    if (!visible && placeholderRef.current) {
      const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
        if (intersectionRatio > 0) {
          setVisible(true);
        }
      });
      observer.observe(placeholderRef.current);
      return () => observer.disconnect();
    }
  }, [visible, placeholderRef]);



  return (
    <CategoryContainer isSelectedCategory={isSelectedCategory} onClick={() => handleOnClick(category)}>
      {visible ?
        globalContext.classCategory === 'electro' ?
          <IconContainer>{mapIcons(category)}</IconContainer> :
          <CategoryImg src={mapIcons(category)} />
        :
        <div style={{ width: 50, height: 50, backgroundColor: '#EEE' }} aria-label={"loading-image"} ref={placeholderRef} />
      }
      <CategoryTitle>
        {category}
      </CategoryTitle>
    </CategoryContainer>
  )
}

export default Category