import { Alert } from '@mui/material'
import styled, { keyframes } from 'styled-components'
import theme, { FONT_LATO } from './theme'

const homeAnimation = keyframes`
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

export const Container = styled.div`
  background: ${theme.palette.background.main};
`

export const HomeContainer = styled(Container)`
  background: linear-gradient(310deg, #fb9d5a 70%,#f0a221 10%,#fb9d5a 70%,#f7d6b0 80%);
  background-size: 300% 100%;
  animation: ${homeAnimation} 8s cubic-bezier(0.4, 0, 1, 1) infinite;
`
export const Title = styled.p`
  font-weight: 600;
  font-size: 22px;
  color: #222324;
  line-height: 28px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding:3px;
  padding-top: 5px;
  font-family: ${FONT_LATO};
`

export const CustomAlert = styled(Alert)`
  
`