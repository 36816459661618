import styled from 'styled-components'
import { FONT_POPPINS } from '../../theme'

export const Title = styled.div`
  font-family: ${FONT_POPPINS};
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 500px){
    font-size: 30px;
  }
`

export const HomeContainer = styled.div`
  background-color: #FFDBA9;
`

