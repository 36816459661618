import styled from 'styled-components'
import { FONT_POPPINS } from '../../../theme'

export const Container = styled.div`
  text-align: center;
  margin-top:40px;
  margin-bottom: 20px;
  @media (min-width: 800px){
    margin-bottom: 0px;
  }
`

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap:50px;
  row-gap: 50px;
`
export const CategoryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover{
    cursor: pointer;
  };
  @media (min-width: 800px){

  }
`

export const CategoryTitle = styled.div`
  font-size: 18px;
  font-family: ${FONT_POPPINS};
  font-weight: 200;
  color: #000000;
  text-transform: capitalize;
`

export const CategoriesTitle = styled.div`
  font-size: 40px;
  font-family: ${FONT_POPPINS};
  font-weight: 600;
  color: #1F1F1F;
  margin-bottom: 32px;
  @media (max-width: 500px){
    font-size: 30px;
    margin-bottom: 20px;
  }
`

export const ImageContainer = styled.div`
  border-radius: 999px;
  background-color: #F4BB6E;
  padding:7px;
`

export const Image = styled.img`
  width: 60px;
  height: 60px;
  
`